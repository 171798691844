.section__title h1{
    font-size: 19px;
    color: #676767;
    margin: 0;
    font-weight: 400;
    line-height: 140%;
}
.submit-ticker{
    background-color: #d3e0e9!important;
    color: #000;
}